.tablesorter tbody > tr:nth-child(even) > td,
.tablesorter tbody > tr:nth-child(even) > th {
    background-color: #ebf0f5;
}
.positive {
  color: green !important;
}
.positive:before {
  content: '+';
}
.neutral {
	color: Sienna !important;
}
.neutral:before {
	content: '+';
}
.negative {
  color: red !important;
}
.percentage:after{
  content: '%';
}

.neutral, .negative {
    font-weight: bold;
}


.active.wc_missing { /*pricer sku display without matching wc link*/
	color: red !important; 
}



.hidden-data {
	display: none;
}

.price-nexus-controls{
	float: right;
  min-width: 900px;
}

#pricerApp {
  font-family: 'Lato', sans-serif;
}
#interface{
  border: 1px solid #faf8b7;
  width: 90%;
  padding: 10px;
  display: block;
}
#interface_options{
   width: 100%;
   display: none;
}
#interface_options label {
  margin-right: 2px;
  margin-top: 13px;
}
#main-status{
  font-size: 1.5em;
  padding: 10px;
  float: left;
  display: inline-block;
}
.sync {
  position: sticky;
  height: 60px;
  width: 100%;
  bottom: 0; 
  left: 0;
}

#toggleAll{
  background-color: #25334E; 
    border: none;
    color: lightgrey;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0px;
}
#toggleAll.active{
  background-color: #5DB19A;
  color: white;
}
#toggleAll:hover{
  background-color: #2B6555;
  color: white;
}
#showProblemsOnly{
  background-color: rgb(80, 15, 15); 
    border: none;
    color: lightgrey;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0px;
}
#showProblemsOnly.active{
  background-color: rgb(177, 93, 93);
  color: white;
}
#showProblemsOnly:hover{
  background-color: rgb(101, 43, 43);
  color: white;
}
#veil{
  z-index: 1;
  background-color: rgba(0,0,0, 0.2);
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  display: none;
}
#sync_prices {
    background-color: #009DA6; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

#sync_prices:hover:not(:disabled) {
  background-color: #01E5F2;
}

#sync_prices:disabled {
  background-color: #353C3D;
}

.tablesorter {
  clear: all;
}
.tablesorter .filtered {
  display: none;
}

.tablesorter tbody > tr > td >div[contenteditable=true]:focus {
  outline: #08f 1px dashed;
  background: #faf8b7;
  resize: none;
}
.tablesorter tr {
  transition: all 10ms ease;
}
td, input[type="text"] {
  padding: 2px;
  font-size: 1.1em;
  font-family: 'Lato', sans-serif;
}
td.no-edit, span.no-edit {
  background-color: rgba(230,191,153,0.5);
}
td.suggested-lc{
  opacity: .7;
}

.tablesorter-blue tr.odd td.purchase-rate, 
.tablesorter-blue tr.odd td.landed-cost{
  background-color: #E2F0D6 !important;
}
.tablesorter-blue tr.even td.purchase-rate, 
.tablesorter-blue tr.even td.landed-cost{
  background-color: #F6FFE0 !important;;
}

.tablesorter-blue tr.even:hover td.purchase-rate, 
.tablesorter-blue tr.odd:hover td.purchase-rate,
.tablesorter-blue tr.even:hover td.landed-cost, 
.tablesorter-blue tr.odd:hover td.landed-cost
{
  background-color: #BDD684 !important;;
}

.tablesorter-blue .saturate {
  filter: saturate(70%);
}

td.editable_updated {
  background-color: green;
  color: white;
}

.sku>a, .zi_name>a{
  text-decoration: none;
  color: inherit;
}

.zi-link {
	color: black !important;
}

.wc_link{
  font-style: italic;
}

.wc_mismatch.active {
  background-color: pink !important;
}

.zi_suspect.active{
  background-color: #faf8b7 !important;
}

.valid.active td{
  /*color: DarkGreen !important;*/
  background-color: DarkSeaGreen !important;
} 

.reason_z2_higher.active{
  color: orange !important;
}

/*.profitMargin{
  font-size: .8em;
  font-style: italic;
}
.profitMargin:before{
  content: '(';
}
.profitMargin:after{
  content: ')';
}*/
/*span.profit.good{
  color: green !important;
}
span.profit.bad {
  color: red !important;
  font-weight: bold !important;
}*/
/*.interface_options label{
  width: 150px;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #faf8b7;
  display: inline-block;
 
}*/
#inventory, .sync {
  display: none; /*don't show table until it is loaded*/
}
.pricer-title{
  font-size: 1.6em ;
}
 /*** custom css only popup ***/
.columnSelectorWrapper {
  position: relative;
  margin: 20px 0;
   
}
.columnSelector, .hidden {
  display: none;
}
.columnSelectorButton {
  /*background: #99bfe6;*/
  border: #888 1px solid;
  color: #111;
  border-radius: 5px;
  padding: 5px;
}
#colSelect1:checked + label {
  background: #5797d7;
  border-color: #555;
}
#colSelect1:checked ~ #columnSelector {
  display: block;
}
.columnSelector {
  width: 150px;
  position: absolute;
  top: 30px;
  padding: 10px;
  background: #fff;
  border: #99bfe6 1px solid;
  border-radius: 5px;
}
.columnSelector label {
  display: block;
  border: none;
}
.columnSelector label:nth-child(1) {
  border-bottom: #99bfe6 solid 1px;
  margin-bottom: 5px;
}
.columnSelector input {
  margin-right: 5px;
}
.columnSelector .disabled {
  color: #ddd;
}

.sync_wc_prices td:first-of-type,
.sync_landed_cost td:first-of-type
{
  background-color: lightyellow !important;
  border: 2px dotted blue;
}
.ps-show {
  text-align: center;
}

.pn-action-button{
  border-radius: 5px;
  padding: 0px 3px;
  background-color: #8DDB95;
  cursor: pointer;
  width: 45px;
}
.pn-action-button:hover{
  background-color: #8DDB95;
}

.swal-add-to-wc {
  width: 850px !important;
  
}
.swal-add-to-wc .swal2-content{
  text-align: left;
}
.swal-add-to-wc input{
  width: 65% !important;
}