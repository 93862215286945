#adjustmentPatterns {
	padding: 10px;
	font-family: 'Lato', sans-serif;
}
#adjustmentPatterns #adjustmentPatternCreator{
	border: 1px solid grey;
	width: 100%;
	padding: 5px;
	margin-bottom: 15px;
}
#adjustmentPatterns .adjustmentPattern {
	border: 1px solid grey;
	background-color: lightblue;
	width: 100%;
	padding: 5px;
	margin-bottom: 5px;
 }
#adjustmentPatterns #patternDescription {
	width: 500px;
	font-weight: normal;
}
#adjustmentPatterns table {
	width: 100%;
}
#adjustmentPatterns .itemSelect {
	width: 100%;
}
#adjustmentPatterns .quantityInput{
	width: 40px;
}
#adjustmentPatterns ul.adjustmentPatternItems{
	list-style: none;
}
#adjustmentPatterns .adjustmentPatternItemQty, #adjustmentPatterns .adjustmentPatternDescription,  #adjustmentPatterns .adjustmentPatternItemSKU{
	font-weight: bold;
}
#adjustmentPatterns .adjustmentPatternItemName {
	font-style: italic;
}
#adjustmentPatterns .multiplier {
	width: 40px;
}
#adjustmentPatterns button.delete {
	color: red;
	margin-left: 10px;
}