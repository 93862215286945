#builderApp {
	min-width: 900px;
	padding: 10px;
	font-family: 'Lato', sans-serif;
}
.app-title {
	margin-top: 0 !important;
}
#builder-composite-item-select-container {
	float: left;
	margin-top: -50px;
	margin-left: 235px;
	z-index: 1;
}
#builder-composite-item-select-container .multiselect{
	width: 500px !important;
	display: inline-block;
}
#selectedCompositeItem {
	display: none;
}
#builder-build-sheet{
	border: 1px solid lightgrey;
	padding: 10px;
	width: 100%;
	margin-bottom: 5px;
}
.builder-composite-item-controls {
	display: block;
}
.builder-composite-item-controls div{
	margin: 5px;
	display: inline-block;
	font-size: 1.4em;
}
.builder-composite-item-qty{
	border: 1px solid lightgrey;
	padding: 5px 10px;
}
.builder-composite-item {
	width: 90%;
	margin-bottom: 10px;
}
.builder-composite-item-name, .builder-component-name{
	font-style: italic;
}
.builder-composite-item ul{
	font-size: 1.3em;
	list-style-type: circle;
}
.builder-composite-item-sku {
	font-weight: bold;
}
.builder-remove-this{
	margin-top: -6px;
}
.builder-composite-item-sku, .builder-composite-item-name {
	line-height: 1.4em
}
#builder-build-sheet-date{
	font-size: 80%;
}
.builder-composite-item-max{
	font-size: 1.1em !important;
}
.builder-component-qty-available, .builder-component-qty-each{
	font-size: .8em !important;
}
.builder-composite-item-max:before{
	content: " (Max ";
}
.builder-composite-item-max:after{
	content: ")";
}

.builder-component-qty-available:before{
	content: " (";
}
.builder-component-qty-available:after{
	content: " available)";
}
.builder-component-qty-each:before{
	content: " (";
}
.builder-component-qty-each:after{
	content: " each)";
}
.unavailable{
	color: red;
}
.print-only { display: none; }

@media print {
        .display-only { display: none !important; visibility: none !important; }
        .print-only { display: block; }
        #builder-build-sheet, .panel { border: none; margin-top: none; }
        #builder-build-sheet { font-size: 130%; }
        .builder-composite-item-max{ display: none !important; visibility: none !important;}
        .builder-composite-item-max:before{ content: ''; }
        .builder-composite-item-max:after{ content: ''; }
}