/*li.list-group-item {
    padding: 7px;
}
*/
.so_date, .li-date, .li-delivery-date{
    font-style: italic;
}
 
.so-missingCount, .so-sku, .li-quantity, .li-sku{
    font-weight: bold;
}

.so-missing-items, .li-quantity, .li-sku {
	font-size: 1.2em;
}
.li-name{
	color: black !important;
}
.raleway {
    font-family: 'Raleway', sans-serif;
}

.largeTxt {
	font-size: 1.2em;
}

@media print { 
  a[href]:after {/*disable bootstrap printing urls*/
    content: none !important;
  }
  #aged-receivables, #aged-payables {
    padding: 0px !important;
    font-size: 10px !important;
    margin: 0px !important;
    text-decoration: none !important;
  }
  #aged-receivables tr:hover, #aged-payables tr:hover {
    text-decoration: none !important;
  }
}

.month-name:after{
  content:":";
}
 
.total-invoices-count {
  font-style: italic;
}

.report-invoice-totals ul li span {
  margin-right: 4px;
}

.report-invoice-totals ul li{
  font-size: 1.2em;
  margin-bottom: 5px;
}

.month-total-line{
  font-size: 1.4em;
  font-weight: bold;
}
.report-invoice-totals td.invoice-number,  .report-invoice-totals td.invoice-date{ 
  min-width: 140px;
}

.report-invoice-totals td.invoice-date{
  font-style: italic;
  font-size: .9em;
}
.report-invoice-totals td.customer-name { 
  width: 350px; 
}
.report-invoice-totals tr {
  border: 1px solid lightgrey;
  padding: 5px;
}

.report-invoice-totals tr:hover  {
  background-color: lightblue;
  cursor: arrow;
}

.report-aged-receivables {
  padding: 10px;
}
.report-aged-receivables table{
  width: 100%;
}

#daterange {
  width: 190px;
  float: right;
  padding: 5px;
}

.date-controls {
  width: 150px;
  float:right;
  margin-top: 28px;
  font-size: 1.1em
}
.date-controls input {
  width: 100px;
  padding: 10px;
  border-radius: 5px;
  vertical-align:middle;
  white-space:normal;
  background:none;
  line-height:1;
}

.ar-row-name {
  width: 300px;
}
#aged-receivables, #aged-payables {
  font-size: 1.1em;
  border-spacing: 10px;
  border-collapse: separate;
}

#aged-receivables td.total, #aged-payables td.total {
  margin: 10px;
}
#aged-receivables .total, #aged-payables .total {
  border-top: 1px solid black;
  width: 120px;
}

#aged-receivables tr:hover td:not(.ar-row-name), #aged-payables tr:hover td:not(.ar-row-name){
  text-decoration: underline darkgrey;
  background-color: lightyellow;
}

 
.report-aged-receivables .customer-picker-container{
  width: 300px;
  margin-top: 28px;
}

#purchaseReviewerApp {
  padding: 7px;
}

#purchaseReviewerApp table {
  width: 100%;
}

#purchaseReviewerApp .li-price-increase{
  color: red;
  font-weight: bold;
}

#purchaseReviewerApp .li-price-decrease{
  color: green;
}

#purchaseReviewerApp tr:hover {
  text-decoration: underline;
}
