#pspApp {
	min-width: 900px;
	padding: 10px;
	font-family: 'Lato', sans-serif;
}

#pspApp table {
	margin:0 auto;
	width: 85%;
	border: 1px solid black;
	border-collapse: collapse;
	margin-bottom: 15px;
}
#pspApp td {
	padding: 0px 5px;
}

#pspApp td {border: 1px solid black; background-color: white;}
#pspApp td.price, #pspApp td.sku { width: 150px; }
#pspApp td.price {text-align: right;}
#pspApp tr.header td{
	background-color: lightblue !important;
	font-weight: bold;
}
#pspApp tr.header td.price{
	text-align: left;
}

.psp-blanket-price, .psp-oem-price{
	width: 10%;
	/*text-align: center;*/
}
.psp-sku{
    width: 20%;
}
/*@media print {
        .display-only { display: none !important; visibility: none !important; }
        .print-only { display: block; }
}*/
.psp-sort-weight-input {
	width: 30px;
	height: 30px;
	line-height: 30px;
	font-size: 1em;
	padding: 5px;
	text-align: center;
}
.psp-category-header{
	/*width: 50%;*/
}
.psp-category-header h3, .psp-category-header h4{
	display: inline-block;
}
.psp-category-sort-controls{
	display: inline-block;
}
.div-fake-hidden {
    width:0px;
    height:0px;
    overflow:hidden;
}
.psp-hide {
	color: red;
}