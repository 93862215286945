#clonerApp {
	min-width: 900px;
	padding: 10px;
	font-family: 'Lato', sans-serif;
}
.app-title {
	margin-top: 0 !important;
}
#clonerApp #builder-composite-item-select-container {
	float: left;
	margin-top: -50px;
	margin-left: 365px;
	z-index: 1;
} 
#selectedCompositeItem {
	display: none;
}
.cloner-composite-item-controls {
	display: block;
}
.cloner-composite-item-controls div{
	margin: 5px;
	display: inline-block;
	font-size: 1.4em;
}
.cloner-composite-item-qty{
	border: 1px solid lightgrey;
	padding: 5px 10px;
}
.cloner-composite-item {
	width: 90%;
	margin-bottom: 10px;
}
 
.cloner-composite-item ul{
	font-size: 1.3em;
	list-style-type: circle;
}
 
.cloner-remove-this{
	height: 20px !important;
	width: 16px !important;
	line-height: 14px !important;
	font-size: 12px !important;
	margin: 0px !important;
	margin-top: -6px !important;
	padding: 2px !important;
}
.cloner-composite-item-sku, .cloner-composite-item-name {
	line-height: 1.4em;
	margin-bottom: 5px; 
}

li.cloner-component-item {
	margin: 1.5em 0;
}
 
#clonerApp li:hover, #clonerApp li:hover .cloner-input-field{
	background-color: lightblue;
}
/*#clonerApp li:hover .cloner-component-qty-each{
	background-color: white;
}*/

.print-only { display: none; }

@media print {
        .display-only { display: none !important; visibility: none !important; }
        .print-only { display: block; }
}

.cloner-input-field {
	padding: 10px;
	border: 1px solid #FFF0A5;
	background-color: #FCFAE1;
}

.cloner-composite-item-rate:before, .cloner-composite-item-purchaserate:before{
	content:"$";
}

.cloner-item-display{
	margin-top: 1.5em;
	display: block;
}
.cloner-item-display p :not(div){
	font-weight: bold;
	font-size: 1.2em;
}